@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply bg-bgGray;
    scroll-behavior: smooth;
  }

/* Use CSS variables in your styles */
body {
    @apply bg-bgGray text-black
}

body.dark {
    @apply bg-gray-800 text-white; /* Dark mode styles */
}

.border {
    border-color: var(--border-color);
}

h1 {
    @apply text-4xl font-extrabold  ;
}

h3 {
    @apply text-2xl font-bold  ;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@layer components {

}